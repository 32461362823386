<template>
    <div class="energyguard">
        <!-- 背景 -->
        <div class="EMS_bg">
            <the-nav></the-nav>
            <div class="EMS_bg_bottom">
                <img
                    class="bg_header"
                    src="../../assets/imgs/img-product-b.png"
                    alt="储能管家"
                />
                <img
                    class="bg_logo"
                    src="../../assets/imgs/img-logo-b.png"
                    alt="储能管家"
                />
                <h1>
                    管理多电站的 <br />
                    数字资产平台
                </h1>
                <h6>电站资产快速上云，管好用好才是硬道理</h6>
                <div class="yuyue_btn yuyue_btn2" @click="booking">
                    预约体验
                </div>
            </div>
        </div>
        <!-- 1 -->
        <div class="EMS_box bg_white">
            <div class="EMS_box_con1">
                <div class="top">
                    <div class="common_title">
                        <p class="cn_title">工单闭环管理</p>
                    </div>
                    <div class="top_description">
                        全类工单管理：故障工单，巡检工单，检修/保养工单。A类故障自动转工单，并与APP联动，形成闭环管理。
                        <br />
                        工单统计分析，响应速度，处理效率，排名统计一目了然。
                    </div>
                </div>
                <img
                    class="bottom"
                    src="../../assets/imgs/img-guard-picture-a.webp"
                    alt="工单闭环管理"
                />
            </div>
        </div>
        <!-- 2 -->
        <div class="EMS_box bg_blue">
            <div class="EMS_box_con1">
                <div class="top">
                    <div class="common_title common_title2">
                        <span class="cn_title" style="color: #fff"
                            >故障智能定位</span
                        >
                    </div>
                    <div
                        class="top_description"
                        style="color: rgba(255, 255, 255, 0.65)"
                    >
                        智能合并多设备联生故障告警，快速定位核心故障，直达问题要点。
                    </div>
                </div>
                <img
                    src="../../assets/imgs/img-guard-picture-b.png"
                    alt="故障智能定位"
                />
            </div>
        </div>
        <!-- 3 -->
        <div class="EMS_box bg_white">
            <div class="EMS_box_con1">
                <div class="top">
                    <div class="common_title">
                        <span class="cn_title" style="color: #0c0e25">
                            三维可视 透彻入里
                        </span>
                    </div>
                    <div
                        class="top_description"
                        style="color: rgba(0, 0, 0, 0.65)"
                    >
                        叠加空间信息 极大提升运维效率
                    </div>
                </div>
                <img
                    src="../../assets/imgs/img-guard-picture-c.webp"
                    alt="三维可视"
                />
            </div>
        </div>
        <!-- 4 -->
        <div class="EMS_box bg_gray">
            <div class="EMS_box_con4">
                <div class="common_title">
                    <span class="cn_title">从粗放运维到精细运维 轻松升级</span>
                    <span class="en_title">upgrade</span>
                </div>
                <div class="swiper proSwiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div
                                class="proSwiper-item"
                                v-for="(item, index) in productList.slice(0, 3)"
                                :key="'pro' + index"
                            >
                                <svg class="left" aria-hidden="true">
                                    <use :xlink:href="`#${item.svg}`"></use>
                                </svg>
                                <div class="right">
                                    <div class="right_title">
                                        {{ item.name }}
                                    </div>
                                    <div class="right_desc">
                                        {{ item.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div
                                class="proSwiper-item"
                                v-for="(item, index) in productList.slice(3)"
                                :key="'pro' + index"
                            >
                                <svg class="left" aria-hidden="true">
                                    <use :xlink:href="`#${item.svg}`"></use>
                                </svg>
                                <div class="right">
                                    <div class="right_title">
                                        {{ item.name }}
                                    </div>
                                    <div class="right_desc">
                                        {{ item.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </div>
        <!-- 5 -->
        <div class="EMS_box bg_white">
            <div class="EMS_box_con5">
                <div class="common_title">
                    <span class="cn_title">请一个好管家，资产管理更放心</span>
                    <span class="en_title">housekeeper</span>
                </div>
                <div class="grid">
                    <div
                        class="grid_item bg_white"
                        v-for="(item, index) in valueList"
                        :key="'value' + index"
                    >
                        <svg class="grid_item_img" aria-hidden="true">
                            <use :xlink:href="`#${item.svg}`"></use>
                        </svg>
                        <div class="grid_item_title">{{ item.name }}</div>
                        <div class="grid_item_desc">
                            <span>{{
                                item.description.substring(
                                    0,
                                    item.description.indexOf(" ")
                                )
                            }}</span>
                            <span>{{
                                item.description.substring(
                                    item.description.indexOf(" ")
                                )
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 6 -->
        <div class="EMS_box bg_gray" style="padding: 0; overflow: hidden">
            <div class="EMS_box_con6">
                <div class="common_title">
                    <span class="cn_title">
                        业务覆盖 <br />
                        中国、德国、新加坡多个国家
                    </span>
                    <span class="en_title">scope of business</span>
                </div>
                <div class="EMS_box_con6_bottom">
                    <div class="EMS_box_con6_bottom_item">
                        <svg class="svg" aria-hidden="true">
                            <use
                                :xlink:href="`#a-icon-guard-fill-map-a1x`"
                            ></use>
                        </svg>
                        <div class="right">
                            <span>30+</span>
                            <span>电站</span>
                        </div>
                    </div>
                    <div class="EMS_box_con6_bottom_item">
                        <svg class="svg" aria-hidden="true">
                            <use
                                :xlink:href="`#a-icon-guard-fill-map-b1x`"
                            ></use>
                        </svg>
                        <div class="right">
                            <span>500+MWh</span>
                            <span>装机总量</span>
                        </div>
                    </div>
                    <div class="EMS_box_con6_bottom_item">
                        <svg class="svg" aria-hidden="true">
                            <use
                                :xlink:href="`#a-icon-guard-fill-map-c1x`"
                            ></use>
                        </svg>
                        <div class="right">
                            <span>70,000+</span>
                            <span>纯碳减排</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- footer -->
        <the-footer></the-footer>
    </div>
</template>

<script>
import Swiper from "swiper/js/swiper.min.js";
import "swiper/css/swiper.min.css";
export default {
    name: "energyguard",
    data() {
        return {
            // 产品特点
            productList: [
                {
                    name: "运维模板",
                    description:
                        "内置国家标准模板包括巡检、保养，并可自定义设置，批量下发。",
                    svg: "a-icon-guard-line-a1x",
                },
                {
                    name: "故障处理图谱",
                    description:
                        "结合多年实地运维经验，为故障处理提供知识图谱，提升消缺效率。",
                    svg: "a-icon-guard-line-b1x",
                },
                {
                    name: "电费结算",
                    description:
                        "打通结算支付通路，用电方可预充现扣，提升多方财务效率，减少拖欠。",
                    svg: "a-icon-guard-line-c1x",
                },
                {
                    name: "设备二维码",
                    description:
                        "实现设备级电子档案管理，便于运维上报，调取设备的运维记录。",
                    svg: "a-icon-guard-line-d1x",
                },
                {
                    name: "统计报表",
                    description:
                        "工单效率，人员绩效，损失收益，运维管理从主观随意到有理有据。",
                    svg: "a-icon-guard-line-e1x",
                },
                {
                    name: "运维专家托管",
                    description:
                        "核心运维托管给专家，降低现场人员要求，更低费用实现高水平运维。",
                    svg: "a-icon-guard-line-f1x",
                },
            ],
            // 价值
            valueList: [
                {
                    name: "升级",
                    description: "摒弃纸笔表格 信息化赋能助力",
                    svg: "a-icon-guard-fill-a1x",
                },
                {
                    name: "降本",
                    description: "更精简的团队 更好的运维表现",
                    svg: "a-icon-guard-fill-b1x",
                },
                {
                    name: "增收",
                    description: "缩短故障时长 更多放电和收益",
                    svg: "a-icon-guard-fill-c1x",
                },
                {
                    name: "沉淀",
                    description: "数据价值沉淀 经验得以传承",
                    svg: "a-icon-guard-fill-d1x",
                },
            ],
        };
    },
    mounted() {
        this.initSwiper();
    },
    methods: {
        booking() {
            this.$store.commit("setState", { visible: true });
        },
        initSwiper() {
            this.$nextTick(() => {
                new Swiper(".swiper", {
                    direction: "horizontal", // 垂直切换选项
                    loop: true,
                    pagination: {
                        el: ".swiper-pagination",
                        bulletClass: "my-bullet",
                        bulletActiveClass: "my-bullet-active",
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss">
.energyguard {
    .EMS_box {
        padding: px2rem(40) px2rem(24);
        .swiper {
            position: relative;
            width: 100%;
            height: px2rem(371);
            overflow: hidden;
            &-wrapper {
                position: relative;
                display: flex;
                width: 100%;
                height: px2rem(341);
            }
            &-slide {
                flex-shrink: 0;
                width: 100%;
                height: 100%;
            }
            &-pagination {
                bottom: 0;
                background-color: transparent;
                .my-bullet {
                    display: inline-block;
                    width: px2rem(6);
                    height: px2rem(6);
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: px2rem(4);
                    margin-right: px2rem(8);
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .my-bullet-active {
                    width: px2rem(12);
                    height: px2rem(6);
                    background-color: #00c699;
                    border-radius: px2rem(4);
                }
            }
        }
        .common_title {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-bottom: px2rem(24);
            .cn_title {
                font-size: px2rem(24);
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
            }
            .en_title {
                font-size: px2rem(14);
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.25);
                text-transform: uppercase;
                margin-top: px2rem(4);
            }
            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
                content: "";
                display: block;
                width: 100%;
                height: px2rem(2);
                background-color: #e6e6e6;
            }
            &::before {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 3;
                content: "";
                display: block;
                width: px2rem(77);
                height: px2rem(2);
                background-color: #00c699;
            }
        }
        .align_center {
            align-items: center;
            &::before {
                left: 50%;
                transform: translateX(-50%);
            }
            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &_con1 {
            .top {
                font-size: px2rem(24);
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: left;
                color: #0c0e25;
                &_description {
                    font-size: px2rem(14);
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.65);
                    margin: px2rem(24) 0;
                    line-height: 2;
                }
            }
            .bottom {
                height: px2rem(435);
            }
        }
        &_con4 {
            .cn_title {
                font-size: px2rem(20) !important;
            }
            .proSwiper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: auto;
                margin-top: px2rem(24);
                padding-bottom: px2rem(24);
                .swiper-wrapper {
                    height: auto;
                }
                &-item {
                    display: flex;
                    padding: px2rem(24) px2rem(16);
                    border-radius: px2rem(4);
                    background-color: #fff;
                    margin-bottom: px2rem(12);
                    .left {
                        width: px2rem(40);
                        height: px2rem(40);
                        margin-right: px2rem(16);
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .right {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        &_title {
                            padding-bottom: px2rem(8);
                            @include thinBorder(bottom, #e6e6e6);
                            font-size: px2rem(16);
                            font-family: PingFangSC, PingFangSC-Medium;
                            font-weight: 600;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.85);
                        }
                        &_desc {
                            margin-top: px2rem(8);
                            font-size: px2rem(14);
                            font-family: PingFangSC, PingFangSC-Regular;
                            font-weight: 400;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.45);
                        }
                    }
                }
            }
        }
        &_con5 {
            .cn_title {
                text-align: left;
                font-size: px2rem(20) !important;
            }
            .grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, px2rem(220));
                grid-gap: px2rem(8) px2rem(8);
                margin: px2rem(24) auto;
                &_item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background: #f4f5f7;
                    border-radius: px2rem(4);
                    &_img {
                        width: px2rem(72);
                        height: px2rem(72);
                        margin-bottom: px2rem(16);
                    }
                    &_title {
                        font-size: px2rem(16);
                        font-family: PingFangSC, PingFangSC-Medium;
                        font-weight: 500;
                        text-align: center;
                        color: rgba(0, 0, 0, 0.85);
                        margin-bottom: px2rem(8);
                    }
                    &_desc {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: px2rem(14);
                        font-family: PingFangSC, PingFangSC-Regular;
                        font-weight: 400;
                        text-align: center;
                        color: rgba(0, 0, 0, 0.45);
                    }
                }
            }
        }
        &_con6 {
            box-sizing: border-box;
            padding: px2rem(40) 0 px2rem(24) 0;
            background-image: url("../../assets/mobile/img-m-guard-earth.png");
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: 100% px2rem(404);
            .common_title {
                margin: 0 px2rem(24);
                .cn_title {
                    font-size: px2rem(20);
                }
            }
            &_bottom {
                display: flex;
                flex-direction: column;
                margin: px2rem(82) px2rem(24) px2rem(40) px2rem(24);
                padding: 0 px2rem(28);
                background-color: rgba(255, 255, 255, 0.85);
                &_item {
                    display: flex;
                    padding: px2rem(16) 0;
                    @include thinBorder(bottom, rgba(0, 0, 0, 0.08));
                    &:first-child {
                        padding-top: px2rem(32);
                    }
                    &:last-child {
                        padding-bottom: px2rem(32);
                    }
                    .svg {
                        width: px2rem(40);
                        height: px2rem(40);
                        margin-right: px2rem(16);
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        font-size: px2rem(12);
                        font-family: PingFangSC, PingFangSC-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: rgba(0, 0, 0, 0.45);
                        > span:first-child {
                            font-size: px2rem(24);
                            font-family: PingFangSC, PingFangSC-Medium;
                            font-weight: 500;
                            text-align: left;
                            color: #00c699;
                        }
                    }
                }
            }
        }
    }
}
</style>
